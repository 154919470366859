import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import {
    InfoCard,
    InfoCardDetails,
    type InfoCardDetailsRow,
    InfoCardHeader,
    formatMoney,
    infoCardDetailsGap,
} from 'components'
import { type InvoiceModel } from 'resources/invoices'
import { Divider, Typography } from 'ui'

const TotalCard: FC = () => {
    const record = useRecordContext<InvoiceModel>()

    if (!record) {
        return null
    }

    return (
        <InfoCard>
            <InfoCardHeader title="Financial Summary" />
            <InfoCardDetails<InvoiceModel> details={details} />
            <Divider sx={{ my: infoCardDetailsGap }} />
            <Typography
                fontWeight="bold"
                color="secondary.main"
                display="flex"
                justifyContent="space-between"
                variant="body2"
            >
                <span>Total</span> {formatMoney(record.total || 0)}
            </Typography>
        </InfoCard>
    )
}

export default TotalCard

const details: InfoCardDetailsRow<InvoiceModel>[] = [
    { label: 'Parts total', source: (record) => formatMoney(record.totalParts) },
    { label: 'Labor total', source: (record) => formatMoney(record.totalLabor) },
    { label: 'Services total', source: (record) => formatMoney(record.totalServices) },
    { label: 'Fees', source: (record) => formatMoney(record.fees) },
    { label: 'Taxes', source: (record) => formatMoney(record.taxes) },
]
