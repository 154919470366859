import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    List,
    ListBase,
    type FilterConfig,
    type ListSortContentProps,
    ListAvatar,
} from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import {
    type CustomerModel,
    type CustomerPricingModel,
    customersResource,
} from 'resources/customers'
import { ptLaborFields, ptPartFields, ptServiceFields, ptTaxFields } from 'resources/pricingTiers'
import { shopFields } from 'resources/shops'
import { PageContent } from 'ui'

import { CustomerPricingDrawerToggler } from './CustomerPricingDrawerToggler'
import ListHeader from './CustomerPricingListHeader'
import { customerPricingPreferencesResource } from './utils'

const defaultSort: SortPayload<CustomerPricingModel> = {
    field: shopFields.self.source,
    order: 'ASC',
}

const sortConfig: ListSortContentProps<CustomerPricingModel> = {
    sortBy: [
        shopFields.self.sort(),
        ptPartFields.self.sort(),
        ptLaborFields.self.sort(),
        ptServiceFields.self.sort(),
        ptTaxFields.self.sort(),
    ],
}

const filtersConfig: FilterConfig<CustomerPricingModel> = {
    filters: [
        ptPartFields.self.filter(),
        ptLaborFields.self.filter(),
        ptServiceFields.self.filter(),
        ptTaxFields.self.filter(),
    ],
}

const columnsConfig: DatagridColumnsProps<CustomerPricingModel> = {
    checkboxSelection: false,
    columns: [
        {
            field: shopFields.avatar.source as keyof CustomerPricingModel,
            headerName: shopFields.avatar.label,
            maxWidth: 72,
            renderCell: (cell) => (
                <ListAvatar
                    id={cell.row.shop}
                    disableLink
                    imageSrc={cell.row.shopData.logo}
                    defaultImage={<shopFields.avatar.Icon record={cell.row.shopData} />}
                    color={cell.row.shopData.avatarColor}
                    avatarOpacity={1}
                />
            ),
        },
        shopFields.self.tableColumn({
            dataToRecord: (record: CustomerPricingModel) => record.shopData,
        }),
        ptPartFields.self.tableColumn({
            dataToRecord: (record: CustomerPricingModel) => record.partTierData,
        }),
        ptLaborFields.self.tableColumn({
            dataToRecord: (record: CustomerPricingModel) => record.laborTierData,
        }),
        ptServiceFields.self.tableColumn({
            dataToRecord: (record: CustomerPricingModel) => record.serviceTierData,
        }),
        ptTaxFields.self.tableColumn({
            dataToRecord: (record: CustomerPricingModel) => record.taxTierData,
            isTaxExempt: (record: CustomerPricingModel) => record.isTaxExempt,
        }),
    ],
    actions: ({ row }, { children, resource }) => [
        <CustomerPricingDrawerToggler
            key="edit"
            record={row}
            resource={resource.resource}
        >
            {({ onClick }) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    onClick,
                })
            }
        </CustomerPricingDrawerToggler>,
    ],
}

const cardsConfig: CardListConfig<CustomerPricingModel> = {
    titleSource: (record) => shopFields.name.value(record.shopData),
    defaultImage: (record) => <shopFields.avatar.Icon record={record.shopData} />,
    avatarColor: (t, record) => record.shopData.avatarColor,
    avatarOpacity: 1,
    disableTitleLink: true,
    details: [
        ptPartFields.self.dataCardRow({
            dataToRecord: (record: CustomerPricingModel) => record.partTierData,
        }),
        ptLaborFields.self.dataCardRow({
            dataToRecord: (record: CustomerPricingModel) => record.laborTierData,
        }),
        ptServiceFields.self.dataCardRow({
            dataToRecord: (record: CustomerPricingModel) => record.serviceTierData,
        }),
        ptTaxFields.self.dataCardRow({
            dataToRecord: (record: CustomerPricingModel) => record.taxTierData,
            isTaxExempt: (record: CustomerPricingModel) => record.isTaxExempt,
        }),
    ],

    actions: (record, { children, resource }) => [
        <CustomerPricingDrawerToggler
            key="edit"
            record={record}
            resource={resource.resource}
        >
            {({ onClick }) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    key: 'edit',
                    onClick,
                })
            }
        </CustomerPricingDrawerToggler>,
    ],
}

const CustomerPricing: FC = () => {
    const record = useRecordContext<CustomerModel>()
    const isLoading = !record

    return (
        <PageContent>
            <ResourceContextProviderWithClearEffect
                value={{
                    ...customerPricingPreferencesResource,
                    resource: `${customersResource.resource}/${record?.id}/pricing`,
                }}
            >
                <ListBase
                    isLoading={isLoading}
                    preferencesResource={customerPricingPreferencesResource}
                    sort={defaultSort}
                >
                    <ListHeader />
                    <List
                        isLoading={isLoading}
                        listFTUProps={{
                            linkText: '',
                        }}
                        preferencesResource={customerPricingPreferencesResource}
                        sortCfg={sortConfig}
                        columnsCfg={columnsConfig}
                        cardsCfg={cardsConfig}
                        filtersCfg={filtersConfig}
                        disableManageColumns
                        disableExportButton
                    />
                </ListBase>
            </ResourceContextProviderWithClearEffect>
        </PageContent>
    )
}

export default CustomerPricing
