/* eslint-disable max-params */
import { type ReactElement } from 'react'

import {
    type ListControllerResult,
    useListContext,
    useRecordContext,
    useShowContext,
} from 'react-admin'

import Icons from 'assets/icons'
import { formatMoney, type UtilityDrawerOpen } from 'components'
import { type ResourceType, useResource } from 'core'
import { LineItemTypeKeys } from 'resources/lineItems'
import { partFields } from 'resources/parts'
import { type VendorModel } from 'resources/vendors'
import { ck33Fields, ck34Fields } from 'resources/vmrs'
import {
    type JobItemModels,
    useWoActionIsDisabled,
    type JobModel,
    type WorkOrderModel,
} from 'resources/workOrders'
import { type SvgIconComponent, SvgIcon } from 'ui'

import {
    editLaborActionExtraArgs,
    editPartActionExtraArgs,
    editPartWithoutNumberActionExtraArgs,
    editVWOItemDrawerArgs,
} from './components/WorkOrderJobCardItems/drawers/drawerArgs'

const useJobItemTranslate = () => {
    const { record: workOrder } = useShowContext<WorkOrderModel>()
    const disabled = useWoActionIsDisabled({ workOrder })
    const resource = useResource()
    const listContext = useListContext()
    const job = useRecordContext<JobModel>()
    const vendor = workOrder.purchaseOrderData?.vendorData || null
    return <K extends keyof JobItemModels>(item: JobItemModels[K]) => {
        return WorkOrderItemsMap[item.type as K](
            item,
            listContext,
            resource,
            disabled,
            vendor,
            job,
            workOrder,
        )
    }
}

export default useJobItemTranslate

export type WorkOrderItemsMapType = {
    [key in keyof JobItemModels]: (
        record: JobItemModels[key],
        listContext: ListControllerResult,
        resource: ResourceType,
        disabled: boolean,
        vendor: VendorModel,
        job: JobModel,
        workOrder: WorkOrderModel,
    ) => {
        icon: SvgIconComponent | ReactElement
        total: (() => number) | number
        totalCaption?: (() => ReactElement | string) | string | ReactElement
        subtitle: string
        action: (open: UtilityDrawerOpen) => void
    }
}

const WorkOrderItemsMap: WorkOrderItemsMapType = {
    [LineItemTypeKeys.LABOR]: (record, listContext, resource, disabled) => ({
        icon: Icons.ScheduleOutlined,
        total: () => {
            const cost = record.hourlyRate
            const qty = record.hours
            return cost * qty
        },
        totalCaption: () => {
            const cost = record.hourlyRate
            const qty = record.hours
            return (
                <>
                    {qty} x {formatMoney(cost)}
                </>
            )
        },
        subtitle: ck33Fields.self.value(record.componentData),
        action: (open) =>
            open(editLaborActionExtraArgs(resource, listContext, record.id, disabled)),
    }),
    [LineItemTypeKeys.PART]: (record, listContext, resource, disabled, vendor, job, workOrder) => ({
        icon: partFields.avatar.Icon,
        total: () => {
            const cost = record.price
            const qty = record.quantity
            return cost * qty
        },
        totalCaption: () => {
            const cost = record.price
            const qty = record.quantity
            return (
                <>
                    {qty} x {formatMoney(cost)}
                </>
            )
        },
        subtitle: `${record.partNumber || ''}${
            record.manufacturerData ? ` • ${ck34Fields.self.value(record.manufacturerData)}` : ''
        }`,
        action: (open) =>
            // check if part is without number
            record.partType === 'VENDOR_HIDDEN'
                ? open(
                      editPartWithoutNumberActionExtraArgs(
                          resource,
                          listContext,
                          record,
                          disabled,
                          job,
                      ),
                  )
                : open(
                      editPartActionExtraArgs(
                          resource,
                          listContext,
                          record.id,
                          disabled,
                          vendor,
                          job,
                          workOrder,
                          { upc: Boolean(vendor), position: record.positionApplicable },
                          vendor ? 'Part (Vendor Parts Catalog)' : null,
                      ),
                  ),
    }),
    [LineItemTypeKeys.FEE]: (record, listContext, resource, disabled) => ({
        icon: (
            <SvgIcon
                component={Icons.Fee}
                inheritViewBox
                color="primary"
                sx={{
                    width: '18px',
                    height: '18px',
                    ...iconSxProps,
                    paddingBottom: '0px',
                }}
            />
        ),
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
        action: (open) =>
            open(editVWOItemDrawerArgs('Fee', resource, listContext, record.id, disabled)),
    }),
    [LineItemTypeKeys.TAX]: (record, listContext, resource, disabled) => ({
        icon: Icons.PercentOutlined,
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
        action: (open) =>
            open(editVWOItemDrawerArgs('Tax', resource, listContext, record.id, disabled)),
    }),
    [LineItemTypeKeys.SERVICE]: (record, listContext, resource, disabled) => ({
        icon: Icons.WorkOutlineOutlined,
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
        action: (open) =>
            open(editVWOItemDrawerArgs('Service', resource, listContext, record.id, disabled)),
    }),
}

export const iconSxProps = {
    height: '16px',
    width: '16px',
    verticalAlign: 'middle',
    paddingBottom: '1px',
    paddingRight: '8px',
    cursor: 'pointer',
}
