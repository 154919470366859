import { type FC } from 'react'

import { useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import { ActionsMenu, type MenuActionProps } from 'core'
import { type InvoiceModel } from 'resources/invoices'

import { InvoiceLaborDrawer } from './InvoiceLaborDrawer'
import InvoicePartDrawer from './InvoicePartDrawer'
import InvoiceServiceDrawer from './InvoiceServiceDrawer'

interface Props extends Pick<MenuActionProps, 'renderToggler'> {}

const ItemsAddMenu: FC<Props> = ({ renderToggler }) => {
    const { record } = useShowContext<InvoiceModel>()
    if (!record) {
        return null
    }
    return (
        <ActionsMenu
            renderToggler={renderToggler}
            actions={(_, { children }) => [
                <InvoicePartDrawer
                    invoice={record}
                    key="add-part"
                >
                    {(open) => children({ Icon: Icons.Parts, title: 'Add Part', onClick: open })}
                </InvoicePartDrawer>,
                <InvoiceLaborDrawer
                    key="add-labor"
                    invoice={record}
                >
                    {(open) =>
                        children({
                            Icon: Icons.ScheduleOutlined,
                            title: 'Add Labor',
                            key: 'add-labor',
                            onClick: open,
                        })
                    }
                </InvoiceLaborDrawer>,
                <InvoiceServiceDrawer
                    key="add-service"
                    invoice={record}
                >
                    {(open) =>
                        children({
                            Icon: Icons.WorkOutlineOutlined,
                            title: 'Add Service',
                            onClick: open,
                        })
                    }
                </InvoiceServiceDrawer>,
            ]}
        />
    )
}

export default ItemsAddMenu
