import { type FC } from 'react'

import { useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import {
    CollapsibleContent,
    InfoCardRowBase,
    InfoCardRows,
    InfoCardTitle,
    InfoCardTitleContainer,
    InfoCardTitleIcon,
    WithCollapsibleController,
} from 'components'
import { InvoiceTypeKeys, type InvoiceItem } from 'resources/invoices'
import { getItemData, ItemRow, ItemsTotal } from 'resources/lineItems'
import { Anchor, Divider } from 'ui'

import { getInvoiceItemDrawer, InvoiceItemsDataMap } from './utils'

const ItemsContent: FC<{ invoiceItems: InvoiceItem[] }> = ({ invoiceItems }) => {
    const { record } = useShowContext()
    let total = 0

    return (
        <>
            <InfoCardRows>
                <InfoCardRowBase
                    label={
                        <InfoCardTitleContainer>
                            <InfoCardTitleIcon Icon={Icons.ListAltOutlined} />
                            <InfoCardTitle>{invoiceItems.length} Line Items</InfoCardTitle>
                        </InfoCardTitleContainer>
                    }
                >
                    {invoiceItems.length && (
                        <WithCollapsibleController>
                            {({ isExpanded, toggleExpand }) => (
                                <Anchor
                                    variant="caption"
                                    onClick={toggleExpand}
                                >
                                    {isExpanded ? `SEE LESS` : `SEE MORE `}
                                </Anchor>
                            )}
                        </WithCollapsibleController>
                    )}
                </InfoCardRowBase>
            </InfoCardRows>
            <CollapsibleContent>
                <Divider />
                <InfoCardRows>
                    {invoiceItems.map((item) => {
                        const EditDrawer = getInvoiceItemDrawer(item.type)
                        const { total: itemTotal } = getItemData(item, InvoiceItemsDataMap)
                        total = total + itemTotal
                        return (
                            <EditDrawer
                                type={
                                    item.jobItem
                                        ? InvoiceTypeKeys.WORK_ORDER
                                        : InvoiceTypeKeys.STANDARD
                                }
                                invoice={record}
                                id={item.id}
                                key={item.id}
                            >
                                {(open) => (
                                    <ItemRow
                                        item={item}
                                        action={open}
                                        customItemMap={InvoiceItemsDataMap}
                                    />
                                )}
                            </EditDrawer>
                        )
                    })}
                </InfoCardRows>
            </CollapsibleContent>
            <ItemsTotal total={total} />
        </>
    )
}

export default ItemsContent
