import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { formatMoney } from 'components'
import { api, type ResourceType } from 'core'
import { type InvoiceItemKeys, type InvoiceItemModels, invoicesResource } from 'resources/invoices'
import { lineItemsDataMap, LineItemTypeKeys } from 'resources/lineItems'
import { pathJoin } from 'utils'

import { InvoiceLaborDrawer } from './InvoiceLaborDrawer'
import InvoicePartDrawer from './InvoicePartDrawer'
import InvoiceServiceDrawer from './InvoiceServiceDrawer'

export const getResource: (id: Identifier, action?: string) => ResourceType = (id, action) => ({
    name: 'invoice-items',
    resource: pathJoin(invoicesResource.resource, id, 'items', action),
})

const ItemTypeDrawerMap = {
    [LineItemTypeKeys.PART]: InvoicePartDrawer,
    [LineItemTypeKeys.LABOR]: InvoiceLaborDrawer,
    [LineItemTypeKeys.SERVICE]: InvoiceServiceDrawer,
}

export const getInvoiceItemDrawer = (type: InvoiceItemKeys) => ItemTypeDrawerMap[type]

export const InvoiceItemsDataMap = {
    ...lineItemsDataMap,
    [LineItemTypeKeys.SERVICE]: (record: InvoiceItemModels[LineItemTypeKeys.SERVICE]) =>
        record.jobItem
            ? {
                  icon: Icons.WorkOutlineOutlined,
                  total: record.quantity * record.price,
                  totalCaption: (
                      <>
                          {record.quantity} x {formatMoney(record.price)}
                      </>
                  ),
                  subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
              }
            : lineItemsDataMap[LineItemTypeKeys.SERVICE](record),
}

export const getSuggestedPrice = (
    id: Identifier,
    data: { type: LineItemTypeKeys; part?: Identifier },
): Promise<{ sellingPrice: number }> => {
    const resource = pathJoin(invoicesResource.resource, id, 'get_direct_suggested_price')
    const response = api.post(resource, data)

    return response
}
