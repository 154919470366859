import { type ReactElement } from 'react'

import { urls } from 'configs'

import { Label as IntegrationsLabel } from '../Integrations/components'

import ConfigureHeader from './ConfigureHeader'

const tabs = [
    {
        value: 'integrations',
        label: <IntegrationsLabel />,
        path: `${urls.configure}/integrations`,
    },
    {
        value: 'tags-management',
        label: 'Tags Management',
        path: `${urls.configure}/tags-management`,
    },
    {
        value: 'purchase-orders',
        label: 'Purchase Orders',
        path: `${urls.configure}/purchase-orders`,
    },
]

const ConfigureLayout = ({ children }: { children: ReactElement }) => {
    return (
        <>
            <ConfigureHeader tabs={tabs} />
            {children}
        </>
    )
}

export default ConfigureLayout
