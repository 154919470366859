import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { CardDefaultSkeleton, InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { type AuthStore } from 'core'
import { displayBooleanValue } from 'utils'

const SettingsCard: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        if (!auth.companyPreferences) {
            return <CardDefaultSkeleton height="101px" />
        }
        return (
            <InfoCard>
                <InfoCardHeader title="Settings" />
                <InfoCardDetails
                    details={[
                        {
                            label: 'Allow duplicate invoices',
                            source: () =>
                                displayBooleanValue(
                                    auth.companyPreferences.allowDuplicatePoInvoiceNumbers,
                                ),
                        },
                    ]}
                    propRecord={auth.currentCompany}
                />
            </InfoCard>
        )
    }),
)

export default SettingsCard
