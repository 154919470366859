import { type FC, type ReactElement } from 'react'

import { inject, observer } from 'mobx-react'

import { useOpenUtilityDrawer, UtilityDrawerForm } from 'components'
import {
    api,
    type AuthStore,
    basePermissions,
    serialize,
    type Serializer,
    updatedMessage,
    useSubmit,
} from 'core'
import { companyPermissionName } from 'resources/company'
import { type CompanyModel } from 'resourcesBase'

import SettingsForm from './SettingsForm'

interface SettingsDrawerTogglerProps {
    children: (params: { onClick: () => void }) => ReactElement
}

const SettingsDrawerToggler: FC<SettingsDrawerTogglerProps> = inject('auth')(
    observer(({ children, auth }: SettingsDrawerTogglerProps & { auth: AuthStore }) => {
        const open = useOpenUtilityDrawer()

        const disabled = !auth.permissions[companyPermissionName][basePermissions.update]
        const handleSubmit = useSubmit(
            async (formData) => {
                await api.patch('company', { preferences: serialize(formData, serializer) })
            },
            {
                successMessage: updatedMessage,
            },
        )

        return children({
            onClick: () => {
                open({
                    drawerArgs: {
                        title: 'Vendors',
                        renderWrapper: (params) => (
                            <UtilityDrawerForm
                                {...params}
                                onSubmit={handleSubmit}
                                defaultValues={{
                                    allowDuplicatePoInvoiceNumbers:
                                        auth.companyPreferences.allowDuplicatePoInvoiceNumbers,
                                }}
                            />
                        ),
                        renderContent: () => <SettingsForm disabled={disabled} />,
                        renderBottomRight: (render) => render({ formType: 'edit' }),
                    },
                    extraArgs: {
                        resource: { resource: 'company', name: 'purchase_order_settings' },
                    },
                })
            },
        })
    }),
)

export default SettingsDrawerToggler

const serializer: Serializer<CompanyModel['preferences']> = [
    { name: 'allowDuplicatePoInvoiceNumbers', parse: 'boolean' },
]
