import { CheckboxInput } from 'components'

const SettingsForm = ({ disabled }: { disabled: boolean }) => {
    return (
        <>
            <CheckboxInput
                source="allowDuplicatePoInvoiceNumbers"
                label="Allow duplicate invoices"
                disabled={disabled}
            />
        </>
    )
}

export default SettingsForm
