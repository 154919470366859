import { type FC } from 'react'

import Icons from 'assets/icons'
import { InfoCardRowBase, MoneyField } from 'components'
import { ManageFeeDrawerToggler } from 'resources/fees'

import ItemLabel from './ItemLabel'

const FeeRow: FC<{ value: number }> = ({ value }) => {
    return (
        <InfoCardRowBase
            label={
                <ManageFeeDrawerToggler>
                    {(open) => (
                        <ItemLabel
                            Icon={Icons.Fee}
                            onClick={open}
                        >
                            Shop Supply Fee
                        </ItemLabel>
                    )}
                </ManageFeeDrawerToggler>
            }
        >
            <MoneyField value={value} />
        </InfoCardRowBase>
    )
}

export default FeeRow
