import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import images from 'assets/images'
import {
    type DatagridColumnsProps,
    List,
    ListAvatar,
    ListBase,
    type CardListConfig,
    type FilterConfig,
    type ListSortContentProps,
    type ListBulkActions,
} from 'components'
import {
    type Action,
    type AuthStore,
    deleteManyFromListAction,
    deleteOneAction,
    editRedirectInListAction,
    multiselectAction,
    type SortPayload,
} from 'core'
import { createdField } from 'resources/base'
import { customerFields } from 'resources/customers'
import {
    InvoiceDrawerToggler,
    type InvoiceModel,
    deleteInvoiceParams,
    deleteInvoiceTitle,
    invoiceFields,
} from 'resources/invoices'
import { shopFields } from 'resources/shops'
import { unitFields } from 'resources/units'
import { woFields } from 'resources/workOrders'
import { LinkButton, PageContent } from 'ui'

import { SendInvoiceAction } from '../Show/components/actions'

import { InvoicesListHeader } from './components'

const InvoicesList: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { columnsConfig, cardsConfig, filterConfig, sortConfig } = getConfig(auth)

        return (
            <ListBase sort={defaultSort}>
                <InvoicesListHeader />
                <PageContent>
                    <List
                        columnsCfg={columnsConfig}
                        cardsCfg={cardsConfig}
                        filtersCfg={filterConfig}
                        sortCfg={sortConfig}
                        bulkActions={bulkActions}
                        listFTUProps={{
                            imageSrc: images.noPayments,
                            title: 'No Invoices',
                            linkText: (
                                <InvoiceDrawerToggler>
                                    {(open) => (
                                        <LinkButton onClick={open}>
                                            Create Direct Invoice
                                        </LinkButton>
                                    )}
                                </InvoiceDrawerToggler>
                            ),
                            linkAction: (e) => {
                                e.preventDefault()
                            },
                            secondaryTitle: 'Would you like to add one?',
                        }}
                    />
                </PageContent>
            </ListBase>
        )
    }),
)

export default InvoicesList

const actions: Action<InvoiceModel> = (record, { children }) => {
    return [
        editRedirectInListAction({ children, id: record.id }),
        children({
            key: 'export-pdf',
            title: 'Export PDF',
            Icon: Icons.Pdf,
        }),
        <SendInvoiceAction
            key="sendInvoice"
            record={record}
            children={children}
        />,
        deleteOneAction({ children, id: record.id, ...deleteInvoiceParams(record) }),
    ]
}

const defaultSort: SortPayload<InvoiceModel> = {
    field: 'created',
    order: 'DESC',
}

const getConfig = (auth: AuthStore) => {
    const cardsConfig: CardListConfig<InvoiceModel> = {
        titleSource: invoiceFields.number.source,
        subTitleSource: (record) => invoiceFields.status.value(record),
        defaultImage: (record) => <invoiceFields.avatar.Icon record={record} />,
        details: [
            shopFields.self.withGuard(
                auth,
                shopFields.self.dataCardRow({
                    dataToRecord: (record: InvoiceModel) => record.shopData,
                }),
            ),
            customerFields.self.dataCardRow({
                dataToRecord: (record: InvoiceModel) => record.customerData,
            }),
            unitFields.self.dataCardRow({
                dataToRecord: (record) => record.unitData,
                withLink: true,
            }),
            woFields.self.dataCardRow({
                withLink: true,
                dataToRecord: (record: InvoiceModel) => record.workOrderData,
            }),
            invoiceFields.poNumber.dataCardRow(),
            invoiceFields.invoiceDate.dataCardRow(),
            invoiceFields.closedDate.dataCardRow(),
            invoiceFields.total.dataCardRow(),
            invoiceFields.balanceDue.dataCardRow(),
        ],
        actions: (record, params) => [
            multiselectAction({ children: params.children, id: record.id }),
            ...actions(record, params),
        ],
    }

    const columnsConfig: DatagridColumnsProps<InvoiceModel & { workOrderCompleted: string }> = {
        columns: [
            {
                field: invoiceFields.avatar.source,
                headerName: invoiceFields.avatar.label,
                maxWidth: 72,
                renderCell: ({ row }) => (
                    <ListAvatar
                        id={row.id}
                        defaultImage={<invoiceFields.avatar.Icon record={row} />}
                    />
                ),
            },
            invoiceFields.number.tableColumn(),
            shopFields.self.withGuard(
                auth,
                shopFields.self.tableColumn({
                    dataToRecord: (record: InvoiceModel) => record.shopData,
                }),
            ),
            invoiceFields.type.tableColumn(),
            customerFields.self.tableColumn({
                dataToRecord: (record: InvoiceModel) => record.customerData,
            }),
            unitFields.self.tableColumn({
                id: 'unitData',
                withLink: true,
                dataToRecord: (record) => record.unitData,
            }),
            woFields.self.tableColumn({
                withLink: true,
                dataToRecord: (record: InvoiceModel) => record.workOrderData,
            }),
            invoiceFields.woCompletedDate.tableColumn(),
            invoiceFields.poNumber.tableColumn(),
            invoiceFields.status.tableColumn(),
            createdField.tableColumn({
                dataToValue: (record) => record.created,
            }),
            invoiceFields.invoiceDate.tableColumn(),
            invoiceFields.dueDate.tableColumn(),
            invoiceFields.closedDate.tableColumn(),
            invoiceFields.total.tableColumn(),
            invoiceFields.paid.tableColumn(),
            invoiceFields.balanceDue.tableColumn(),
        ],
        actions: ({ row }, args) => actions(row, args),
        actionsWidth: 156,
    }

    const filterConfig: FilterConfig<InvoiceModel & { workOrderCompleted: string }> = {
        filters: [
            shopFields.self.withGuard(auth, shopFields.self.filter()),
            invoiceFields.type.filter(),
            customerFields.self.filter(),
            unitFields.self.filter(),
            woFields.self.filter(),
            invoiceFields.woCompletedDate.filter(),
            invoiceFields.status.filter(),
            createdField.filter(),
            invoiceFields.invoiceDate.filter(),
            invoiceFields.closedDate.filter(),
            invoiceFields.total.filter(),
        ],
    }

    const sortConfig: ListSortContentProps<InvoiceModel & { workOrderCompleted: string }> = {
        sortBy: [
            invoiceFields.number.sort(),
            shopFields.self.withGuard(auth, shopFields.self.sort()),
            invoiceFields.type.sort(),
            customerFields.self.sort(),
            unitFields.self.sort(),
            woFields.self.sort(),
            invoiceFields.woCompletedDate.sort(),
            invoiceFields.poNumber.sort(),
            invoiceFields.status.sort(),
            createdField.sort(),
            invoiceFields.invoiceDate.sort(),
            invoiceFields.closedDate.sort(),
            invoiceFields.total.sort(),
            invoiceFields.paid.sort(),
            invoiceFields.balanceDue.sort(),
        ],
    }

    return {
        sortConfig,
        filterConfig,
        columnsConfig,
        cardsConfig,
    }
}

const bulkActions: ListBulkActions = ({ children }) => {
    return [
        deleteManyFromListAction({
            children,
            confirmConfig: {
                title: deleteInvoiceTitle,
            },
        }),
    ]
}
