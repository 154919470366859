import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { GridContainer, GridItemLayout, IconBox, PageContent } from 'ui'

import { SettingsCard, SettingsDrawerToggler } from './components'

const PurchaseOrders = () => {
    return (
        <PageContent>
            <ViewHeader title="Purchase Orders">
                <ViewHeader.Content placement="rightMobile">
                    <SettingsDrawerToggler>
                        {({ onClick }) => (
                            <IconBox
                                title="Edit"
                                onClick={onClick}
                            >
                                <Icons.Edit />
                            </IconBox>
                        )}
                    </SettingsDrawerToggler>
                </ViewHeader.Content>
            </ViewHeader>
            <GridContainer>
                <GridItemLayout>
                    <SettingsCard />
                </GridItemLayout>
            </GridContainer>
        </PageContent>
    )
}

export default PurchaseOrders
