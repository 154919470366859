import { type FC, type ReactElement } from 'react'

import { useRecordContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import {
    useOpenUtilityDrawer,
    UtilityDrawerEditor,
    type UtilityDrawerEditorProps,
} from 'components'
import { type Serializer } from 'core'
import { customerFields } from 'resources/customers'
import { shopFields } from 'resources/shops'
import { unitFields } from 'resources/units'
import woFields from 'resources/workOrders/fields'

import { InvoiceStatusKeys, InvoiceTypeKeys, type InvoiceModel } from '../types'
import { invoicesResource } from '../utils'

import InvoiceForm, { type InvoiceFormProps } from './InvoiceForm'

interface InvoiceDrawerTogglerProps
    extends Pick<InvoiceFormProps, 'isDisabled'>,
        Pick<UtilityDrawerEditorProps, 'successMessage'> {
    children: (open: () => void) => ReactElement
    id?: Identifier
    defaultValues?: Partial<InvoiceModel>
    type?: InvoiceTypeKeys
}

const InvoiceDrawerToggler = ({
    isDisabled,
    defaultValues,
    id,
    children,
    type = InvoiceTypeKeys.STANDARD,
}: InvoiceDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()
    const serializer = getInvoiceDrawerTogglerSerializer(type)
    return children(() => {
        open({
            extraArgs: {
                resource: invoicesResource,
                id,
            },
            drawerArgs: {
                title: id ? <EditTitle /> : 'Create Invoice',
                renderWrapper: (props) => (
                    <UtilityDrawerEditor
                        defaultValues={{ type, ...defaultValues }}
                        serializer={serializer}
                        {...props}
                    />
                ),
                renderContent: () => (
                    <FormContent
                        isDisabled={isDisabled}
                        id={id}
                        type={type}
                    />
                ),
                renderBottomRight: (renderButton) => (
                    <SubmitButtonWrapper id={id}>{renderButton()}</SubmitButtonWrapper>
                ),
            },
        })
    })
}

export default InvoiceDrawerToggler

const getInvoiceDrawerTogglerSerializer: (type: InvoiceTypeKeys) => Serializer<InvoiceModel> = (
    type,
) => {
    const baseSerializer: Serializer<InvoiceModel> = [
        'customer',
        'poNumber',
        { name: 'invoiceDate', parse: 'date' },
        'paymentTerm',
        'shop',
        { name: 'type', parse: 'selfValue' },
    ]
    if (type === InvoiceTypeKeys.STANDARD) {
        return baseSerializer
    }
    return ['workOrder', 'unit', ...baseSerializer]
}
const EditTitle: FC = () => {
    const record = useRecordContext<InvoiceModel>()

    if (!record) {
        return null
    }

    if (record.status !== InvoiceStatusKeys.OPEN) {
        return <>Details</>
    }

    return <>Edit Invoice</>
}

interface FormContentProps extends Pick<InvoiceFormProps, 'isDisabled'> {
    id?: Identifier
    type: InvoiceTypeKeys
}

const FormContent: FC<FormContentProps> = ({ isDisabled, id, type: typeProp }) => {
    const record = useRecordContext<InvoiceModel>()

    if (id && !record) {
        return null
    }

    const type = record?.type || typeProp

    return (
        <InvoiceForm
            isHidden={(source) => {
                if (type === InvoiceTypeKeys.STANDARD) {
                    return source === unitFields.self.source || source === woFields.self.source
                }
            }}
            isDisabled={(source) => {
                if (!id) {
                    return isDisabled?.(source)
                }

                if (record?.status !== InvoiceStatusKeys.OPEN && id) {
                    return true
                }

                if (source === customerFields.self.source || source === shopFields.self.source) {
                    return true
                }

                if (type === InvoiceTypeKeys.WORK_ORDER) {
                    return source === woFields.self.source || source === unitFields.self.source
                }
            }}
        />
    )
}

const SubmitButtonWrapper: FC<{ id: Identifier | null; children: ReactElement }> = ({
    id,
    children,
}) => {
    const record = useRecordContext<InvoiceModel>()

    if (id && record?.status !== InvoiceStatusKeys.OPEN) {
        return null
    }

    return children
}
