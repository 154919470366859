import { Navigate, Route, Routes } from 'lib'

import Integrations, { IntegrationsUnitPage } from './Integrations'
import { PurchaseOrders } from './PurchaseOrders'
import { TagsManagement } from './TagsManagement'
import { ConfigureLayout } from './components'

const Configure = () => {
    return (
        <Routes>
            <Route
                path="integrations"
                element={
                    <ConfigureLayout>
                        <Integrations />
                    </ConfigureLayout>
                }
            />
            <Route
                path="sync-vehicles/:telematic"
                element={<IntegrationsUnitPage />}
            />
            <Route
                path="tags-management"
                element={
                    <ConfigureLayout>
                        <TagsManagement />
                    </ConfigureLayout>
                }
            />
            <Route
                path="purchase-orders"
                element={
                    <ConfigureLayout>
                        <PurchaseOrders />
                    </ConfigureLayout>
                }
            />
            <Route
                path="*"
                element={<Navigate to="integrations" />}
            />
        </Routes>
    )
}

export default Configure
