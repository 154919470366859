import { type Identifier } from 'appTypes'
import { shopResource } from 'resources/shops'
import { pathJoin } from 'utils'

export const getPricingTierResource = (shopId: Identifier, pricingTier: PricingTierTypes) => {
    return pathJoin(shopResource.resource, shopId, 'pricing-tiers', pricingTier)
}

export const taxExemptName = 'Tax Exempt'

export enum PricingTierTypes {
    PARTS = 'parts',
    FEES = 'fees',
    LABOR = 'labor',
    SERVICES = 'services',
    TAXES = 'tax',
}

export const getDefaultText = (name: string) => {
    return `Default (${name})`
}
