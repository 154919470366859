import { type ReactElement } from 'react'

import { type SvgIconElement } from 'appTypes'
import Icons from 'assets/icons'
import { formatMoney } from 'components'
import { partFields } from 'resources/parts'
import { ck33Fields, ck34Fields } from 'resources/vmrs'
import { type SvgIconComponent } from 'ui'

import { type BaseLineItemModels, LineItemTypeKeys } from './types'

export const getItemData = <K extends keyof BaseLineItemModels>(
    item: BaseLineItemModels[K],
    customItemMap?: LineItemsMapType,
) => (customItemMap ? customItemMap[item.type as K](item) : lineItemsDataMap[item.type as K](item))

export type LineItemsMapType = {
    [key in keyof BaseLineItemModels]: (record: BaseLineItemModels[key]) => {
        icon: SvgIconComponent | SvgIconElement
        total: number
        totalCaption?: ReactElement
        subtitle: string
    }
}
export const lineItemsDataMap: LineItemsMapType = {
    [LineItemTypeKeys.LABOR]: (record) => ({
        icon: Icons.ScheduleOutlined,
        total: record.hourlyRate * record.hours,
        totalCaption: (
            <>
                {record.hours} x {formatMoney(record.hourlyRate)}
            </>
        ),
        subtitle: ck33Fields.self.value(record.componentData),
    }),
    [LineItemTypeKeys.PART]: (record) => ({
        icon: partFields.avatar.Icon,
        total: record.price * record.quantity,
        totalCaption: (
            <>
                {record.quantity} x {formatMoney(record.price)}
            </>
        ),
        subtitle: `${record.partNumber || ''}${
            record.manufacturerData ? ` • ${ck34Fields.self.value(record.manufacturerData)}` : ''
        }`,
    }),
    [LineItemTypeKeys.FEE]: (record) => ({
        icon: Icons.Fee,
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
    }),
    [LineItemTypeKeys.TAX]: (record) => ({
        icon: Icons.PercentOutlined,
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
    }),
    [LineItemTypeKeys.SERVICE]: (record) => ({
        icon: Icons.WorkOutlineOutlined,
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
    }),
}
