import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { type RaRecord } from 'react-admin'

import {
    List,
    ListBase,
    type DatagridColumnsProps,
    ListAvatar,
    type ListBulkActions,
    type CardListConfig,
    type ListSortContentProps,
    type FilterConfig,
    ListFilterDateRangeValueInput,
    ListFilterValueInput,
    ListFilterRangeInput,
    PeriodRangeWithTypeFilter,
    formatMoney,
    costMaskParams,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import {
    type AuthStore,
    baseOperations,
    deleteManyFromListAction,
    deleteOneAction,
    multiselectAction,
    type SortPayload,
    useCanAccess,
} from 'core'
import { formatDate, formatDuration } from 'lib'
import { invoiceFields } from 'resources/invoices'
import { shopFields } from 'resources/shops'
import { unitFields } from 'resources/units'
import {
    woFields,
    type WoTypeChoice,
    woTypeChoices,
    type WorkOrderModel,
    type WorkOrderType,
    WithIsVendorWO,
    type WoStatusKeys,
    vendorWODeleteDialogAction,
    deleteWorkOrderAction,
} from 'resources/workOrders'
import { PageContent } from 'ui'

import { WorkOrderDrawerToggler, WorkOrderSubtitle } from '../components'

import { editRedirectWOFromListAction, pdfOneWOFromListAction } from './actions'
import { WOListHeader } from './components'

const defaultWorkOrderSort: SortPayload<WorkOrderModel> = {
    field: 'created',
    order: 'DESC',
}

interface AdditionalOrdering {
    priority: string
    repairPriorityClass: string
    status: string
}

const bulkActions: ListBulkActions = ({ children }) => [
    deleteManyFromListAction({
        children,
        confirmConfig: {
            title: 'Are you sure you want to delete the selected Work Orders?',
            content:
                "Deleting the selected WOs will remove their jobs' connections to any PM/DVIR/Issues and will return them to their initial state.",
        },
    }),
]

export const getWoCardsCfg: (
    auth: AuthStore,
    getCanAccess?: (
        action: string,
        record?: RaRecord,
    ) => {
        value: boolean
        message: string
    },
) => CardListConfig<WorkOrderModel & { customer?: string; invoice?: string }> = (
    auth,
    getCanAccess,
) => {
    const details: CardListConfig<
        WorkOrderModel & { customer?: string; invoice?: string }
    >['details'] = [
        {
            source: 'started',
            label: ({ record }) => {
                const { status } = record
                if (status === 'CANCELED') {
                    return 'Canceled on Date'
                }
                if (status === 'CLOSED') {
                    return 'Completion Date & Time'
                }
                return 'Start Date & Time'
            },
            render: (value, record) => {
                const { status } = record
                if (status === 'CANCELED') {
                    return formatDate(
                        record.statusUpdated,
                        (dateFormats) => dateFormats.fullDateTime,
                    )
                }
                if (status === 'CLOSED') {
                    return formatDate(record.completed, (dateFormats) => dateFormats.fullDateTime)
                }
                return formatDate(value, (dateFormats) => dateFormats.fullDateTime)
            },
        },
        shopFields.self.withGuard(
            auth,
            shopFields.self.dataCardRow({ dataToRecord: (data: WorkOrderModel) => data.shopData }),
        ),
        {
            source: 'priority',
            render: (value, data) => woFields.priority.value(data),
        },
        {
            source: 'repairPriorityClass',
            label: 'Repair class',
            render: (value, data) => woFields.repairPriorityClass.value(data),
        },
        {
            source: 'vendor',
            label: 'Vendor',
            render: (value, record) => record.purchaseOrderData?.vendorData.name,
        },
        {
            source: 'purchaseOrder',
            label: 'Vendor PO Number',
            render: (value, record) => record.purchaseOrderData?.number,
        },
        auth.companySettings.hasCustomers && {
            source: 'customer',
            label: 'Customer',
            render: (v, record) => record.unitData.customerData?.name,
        },
        auth.companySettings.hasInvoicing &&
            invoiceFields.self.dataCardRow({
                dataToRecord: (record: WorkOrderModel) => record.salesOrderData?.invoiceData,
            }),
        {
            source: 'jobsCount',
            label: 'Jobs',
        },
        {
            source: 'total',
            label: 'Total',
            render: (value) => formatMoney(value),
        },
    ]
    return {
        titleSource: 'number',
        subTitleSource: (workOrder) => <WorkOrderSubtitle workOrder={workOrder} />,
        defaultImage: (record) => <woFields.avatar.RecordIcon record={record} />,
        details: details.filter(Boolean),
        actions: (record, { children, resource }) => {
            const canAccess = getCanAccess(baseOperations.delete, record)
            return [
                editRedirectWOFromListAction({ children, id: record.id }),
                multiselectAction({
                    children,
                    id: record.id,
                }),
                pdfOneWOFromListAction({ record, children }),

                <WithIsVendorWO
                    record={record}
                    key="deleteOne"
                >
                    {(isVendorWorkOrder) =>
                        deleteOneAction({
                            disabled: !canAccess.value,
                            id: record.id,
                            ...(isVendorWorkOrder && record.purchaseOrderData?.totalInvoices
                                ? vendorWODeleteDialogAction()
                                : deleteWorkOrderAction(record, resource!)),
                            children: (params) =>
                                children({
                                    ...params,
                                    titleOnDisabled: canAccess.message,
                                }),
                        })
                    }
                </WithIsVendorWO>,
            ]
        },
    }
}

const getSortCfg: (
    auth: AuthStore,
) => ListSortContentProps<
    WorkOrderModel & AdditionalOrdering & { duration: string; customer: string; poNumber: string }
> = (auth) => {
    const sortBy: ListSortContentProps<
        WorkOrderModel &
            AdditionalOrdering & { duration: string; customer: string; poNumber: string }
    >['sortBy'] = [
        { id: 'created', label: 'Created on' },
        { id: 'completed', label: 'Completed on' },
        { id: 'duration', label: 'WO Duration' },
        { id: 'number', label: 'WO Number' },
        shopFields.self.withGuard(auth, shopFields.self.sort()),
        { id: 'unit', label: 'Unit Number' },
        { id: 'priority', label: 'Priority' },
        { id: 'status', label: 'Status' },
        { id: 'repairPriorityClass', label: 'Repair Class' },
        { id: 'vendor', label: 'Vendor' },
        { id: 'purchaseOrder', label: 'Vendor PO Number' },
        auth.companySettings.hasCustomers && {
            id: 'customer',
            label: 'Customer',
        },
        auth.companySettings.hasCustomers &&
            auth.companySettings.hasInvoicing && {
                id: 'poNumber',
                label: 'Customer PO Number',
            },
        { id: 'jobsCount', label: 'Jobs' },
        { id: 'total', label: 'Total' },
    ]
    return {
        sortBy: sortBy.filter(Boolean),
    }
}

const getFiltersCfg: (
    auth: AuthStore,
) => FilterConfig<WorkOrderModel & { type: WorkOrderType; duration: string; customer: string }> = (
    auth,
) => {
    const filters: FilterConfig<
        WorkOrderModel & { type: WorkOrderType; duration: string; customer: string }
    >['filters'] = [
        {
            id: 'created',
            label: 'Created on',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        shopFields.self.withGuard(auth, shopFields.self.filter()),
        {
            id: 'completed',
            label: 'Completed on',
            filterType: 'range',
            renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
        },
        {
            id: 'duration',
            label: 'WO Duration',
            filterType: 'range',
            renderComponent: (props) => (
                <PeriodRangeWithTypeFilter
                    {...props}
                    inputMaxProps={{ maxValueLength: 9 }}
                    inputMinProps={{ maxValueLength: 9 }}
                    choices={['h', 'd']}
                />
            ),
        },
        { id: 'unit', label: 'Unit Number' },
        woFields.priority.filter(),
        {
            id: 'status',
            label: 'Status',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    inputText={(option) => option.id}
                    makeItemLabel={(record) => {
                        return woFields.status.value({ status: record.id as WoStatusKeys })
                    }}
                />
            ),
        },
        woFields.repairPriorityClass.filter(),
        { id: 'vendor', label: 'Vendor' },
        auth.companySettings.hasCustomers && { id: 'customer', label: 'Customer' },
        {
            id: 'total',
            label: 'Total',
            filterType: 'range',
            renderComponent: (props) => (
                <ListFilterRangeInput
                    inputProps={costMaskParams}
                    {...props}
                />
            ),
        },
        {
            id: 'type',
            label: 'WO Type',
            renderComponent: (props) => (
                <ListFilterValueInput
                    {...props}
                    source="type"
                    makeItemLabel={(record) => record.name}
                    matchSuggestion={(filter, choice: WoTypeChoice) => {
                        return choice.name?.toLowerCase().includes(filter?.toLowerCase())
                    }}
                    dataProviderProps={{
                        getList: {
                            makeResponse: () => woTypeChoices,
                        },
                        getMany: {
                            makeResponse: (resource, { ids }) => {
                                return ids.map((id) =>
                                    woTypeChoices.find((choice) => choice.id === id),
                                )
                            },
                        },
                    }}
                />
            ),
        },
        {
            id: 'jobsCount',
            label: 'Jobs',
        },
    ]
    return {
        filters: filters.filter(Boolean),
    }
}

const getColumnsCfg: (
    auth: AuthStore,
    getIsAllowed: (
        action: string,
        record?: RaRecord,
    ) => {
        value: boolean
        message: string
    },
) => DatagridColumnsProps<
    WorkOrderModel & {
        image: string
        duration: string
        customer: string
        invoice: string
        poNumber: string
    }
> = (auth, getCanAccess) => {
    const columns: DatagridColumnsProps<
        WorkOrderModel & {
            image: string
            duration: string
            customer: string
            invoice: string
            poNumber: string
        }
    >['columns'] = [
        {
            field: 'image',
            headerName: 'Avatar',
            maxWidth: 72,
            renderCell: ({ row }) => (
                <ListAvatar
                    linkProps={{ 'aria-label': `Open Work Order ${row.number}` }}
                    id={row.id}
                    defaultImage={<woFields.avatar.RecordIcon record={row} />}
                />
            ),
        },
        {
            field: 'number',
            headerName: 'WO Number',
            flex: 1,
            renderCell: (params) => <DatagridLink {...params} />,
        },
        shopFields.self.withGuard(
            auth,
            shopFields.self.tableColumn({ dataToRecord: (data: WorkOrderModel) => data.shopData }),
        ),
        {
            field: 'created',
            headerName: 'Created on',
            flex: 1,
            valueGetter: ({ value }) => {
                return formatDate(value, (dateFormats) => dateFormats.shortenedDateTime)
            },
        },
        {
            field: 'completed',
            headerName: 'Completed on',
            flex: 1,
            valueGetter: ({ value }) =>
                formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
        },

        {
            field: 'duration',
            headerName: 'WO Duration',
            flex: 1,
            valueGetter: ({ row }) => formatDuration(row.durationSeconds, true),
        },
        {
            field: 'unitData',
            headerName: 'Unit Number',
            flex: 1,
            renderCell: ({ value }) => unitFields.self.linkValue(value),
        },
        {
            field: 'priority',
            headerName: 'Priority',
            renderCell: ({ row }) => woFields.priority.value(row),
        },
        {
            field: woFields.status.source,
            headerName: woFields.status.label,
            renderCell: ({ row }) => {
                return woFields.status.value(row)
            },
        },
        woFields.repairPriorityClass.tableColumn(),
        {
            field: 'vendor',
            headerName: 'Vendor',
            valueGetter: ({ row }) => row.purchaseOrderData?.vendorData.name,
        },
        {
            field: 'purchaseOrder',
            headerName: 'Vendor PO Number',
            flex: 1,
            valueGetter: ({ row }) => row.purchaseOrderData?.number,
        },
        auth.companySettings.hasCustomers && {
            field: 'customer',
            headerName: 'Customer',
            flex: 1,
            valueGetter: ({ row }) => row.unitData.customerData?.name,
        },
        auth.companySettings.hasCustomers &&
            auth.companySettings.hasInvoicing && {
                field: 'poNumber',
                headerName: 'Customer PO Number',
                flex: 1,
                valueGetter: ({ row }) => row.salesOrderData?.poNumber,
            },
        auth.companySettings.hasInvoicing &&
            invoiceFields.self.tableColumn({
                dataToRecord: (record: WorkOrderModel) => record.salesOrderData?.invoiceData,
            }),
        {
            field: 'jobsCount',
            headerName: 'Jobs',
            flex: 1,
            valueGetter: ({ value }) => (value >= 1 ? value : null),
        },
        {
            field: 'total',
            headerName: 'Total',
            flex: 1,
            align: 'right',
            headerAlign: 'right',
            renderCell: ({ value }) => formatMoney(value),
        },
    ]
    return {
        resetColumns: {
            purchaseOrderTotal: false,
            poNumber: false,
        },
        constantColumns: {
            number: true,
        },
        columns: columns.filter(Boolean),
        actions: ({ row: workOrder }, { children, resource }) => {
            const canAccess = getCanAccess(baseOperations.delete, workOrder)
            return [
                editRedirectWOFromListAction({
                    children,
                    id: workOrder.id,
                }),
                pdfOneWOFromListAction({
                    children,
                    record: workOrder,
                }),
                <WithIsVendorWO
                    record={workOrder}
                    key="deleteOne"
                >
                    {(isVendorWorkOrder) =>
                        deleteOneAction({
                            disabled: !canAccess.value,
                            id: workOrder.id,
                            ...(isVendorWorkOrder && workOrder.purchaseOrderData?.totalInvoices
                                ? vendorWODeleteDialogAction()
                                : deleteWorkOrderAction(workOrder, resource!)),
                            children: (params) =>
                                children({
                                    ...params,
                                    titleOnDisabled: canAccess.message,
                                }),
                        })
                    }
                </WithIsVendorWO>,
            ]
        },
    }
}

const WorkOrdersList: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const getCanAccess = useCanAccess()

        return (
            <ListBase<WorkOrderModel> sort={defaultWorkOrderSort}>
                <WOListHeader />
                <PageContent>
                    <List
                        bulkActions={bulkActions}
                        sortCfg={getSortCfg(auth)}
                        columnsCfg={getColumnsCfg(auth, getCanAccess)}
                        cardsCfg={getWoCardsCfg(auth, getCanAccess)}
                        filtersCfg={getFiltersCfg(auth)}
                        listFTUProps={{
                            linkAction: () => {
                                /* */
                            },
                            linkText: (
                                <WorkOrderDrawerToggler>
                                    {({ onClick }) => (
                                        <span onClick={onClick}>Create Work Order</span>
                                    )}
                                </WorkOrderDrawerToggler>
                            ),
                            secondaryTitle: 'Would you like to create one?',
                        }}
                    />
                </PageContent>
            </ListBase>
        )
    }),
)

export default WorkOrdersList
