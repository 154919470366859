import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import {
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    type ListSortContentProps,
    type ListTotalBadgeProps,
} from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload, type ResourceType } from 'core'
import { type AuthStore } from 'core/auth'
import { LineItemTypeKeys } from 'resources/lineItems'
import { tagFields } from 'resources/tags'
import { unitFields, type UnitModel } from 'resources/units'
import {
    type CK33Aspects,
    type CK33Model,
    ck33Resource,
    ck33Fields,
    type CK33AspectTotals,
    CK2Field,
    ck34Fields,
} from 'resources/vmrs'
import { woFields } from 'resources/workOrders'
import { PageContent } from 'ui'

import { reportCostCenterFilter, reportDivisionFilter } from '../utils'

import ListWithPeriodSelector, { ListBaseWithPeriod } from './ListWithPeriodSelector'
import ReportsHeader from './ReportsHeader'

interface CK33ReportModel extends CK33Model, CK33AspectTotals {
    description: string
}

const defaultSort: SortPayload<CK33ReportModel> = {
    field: 'total',
    order: 'DESC',
}

interface Props {
    preferences: ResourceType
    aspects: CK33Aspects[]
    codeLabel: string
    descriptionLabel: string
    renderTotal: ListTotalBadgeProps<CK33ReportModel>['renderContent']
    codeValue: (record: CK33ReportModel) => string
    exportFileName: string
    title: string
}

const CostPerCK33: FC<Props> = inject('auth')(
    observer(
        ({
            preferences,
            aspects,
            codeLabel,
            descriptionLabel,
            renderTotal,
            codeValue,
            exportFileName,
            title,
            auth,
        }: Props & { auth: AuthStore }) => {
            const columnsConfig: DatagridColumnsProps<CK33ReportModel> = {
                checkboxSelection: false,
                constantColumns: {
                    text: true,
                    code: true,
                },
                columns: [
                    {
                        field: 'code',
                        headerName: codeLabel,
                        renderCell: ({ row }) => codeValue(row),
                    },
                    {
                        field: 'text',
                        headerName: descriptionLabel,
                    },
                    woFields.total.tableColumn(LineItemTypeKeys.PART),
                    woFields.total.tableColumn(LineItemTypeKeys.LABOR),
                    woFields.total.tableColumn(LineItemTypeKeys.SERVICE),
                    woFields.total.tableColumn(LineItemTypeKeys.FEE),
                    woFields.total.tableColumn(LineItemTypeKeys.TAX),
                    woFields.total.tableColumn(),
                ],
                actions: null,
            }

            const sortConfig: ListSortContentProps<CK33ReportModel> = {
                sortBy: [
                    { id: 'code', label: codeLabel },
                    { id: 'description', label: descriptionLabel },
                    woFields.total.sort(LineItemTypeKeys.PART),
                    woFields.total.sort(LineItemTypeKeys.LABOR),
                    woFields.total.sort(LineItemTypeKeys.SERVICE),
                    woFields.total.sort(LineItemTypeKeys.FEE),
                    woFields.total.sort(LineItemTypeKeys.TAX),
                    woFields.total.sort(),
                ],
            }

            const cardsConfig: CardListConfig<CK33ReportModel> = {
                titleSource: codeValue,
                disableTitleLink: true,
                defaultImage: null,
                details: [
                    {
                        source: 'text',
                        label: descriptionLabel,
                    },
                    woFields.total.dataCardRow(LineItemTypeKeys.PART),
                    woFields.total.dataCardRow(LineItemTypeKeys.LABOR),
                    woFields.total.dataCardRow(LineItemTypeKeys.SERVICE),
                    woFields.total.dataCardRow(LineItemTypeKeys.FEE),
                    woFields.total.dataCardRow(LineItemTypeKeys.TAX),
                    woFields.total.dataCardRow(),
                ],
            }

            const filterConfig: FilterConfig<CK33ReportModel & UnitModel> = {
                filters: [
                    ck33Fields.self.filter<CK33ReportModel>({
                        id: 'code',
                        label: codeLabel,
                        makeItemLabel: codeValue,
                    }),
                    woFields.total.filter(LineItemTypeKeys.PART),
                    woFields.total.filter(LineItemTypeKeys.LABOR),
                    woFields.total.filter(LineItemTypeKeys.SERVICE),
                    woFields.total.filter(LineItemTypeKeys.FEE),
                    woFields.total.filter(LineItemTypeKeys.TAX),
                    woFields.total.filter(),
                    unitFields.created.filter({ id: 'unitCreated' }),
                    unitFields.number.filter({ id: 'unitNumber' }),
                    unitFields.name.filter({ id: 'unitName', label: unitFields.name.longLabel }),
                    unitFields.vin.filter({ id: 'unitVin' }),
                    unitFields.licensePlate.filter({ id: 'unitLicensePlate' }),
                    unitFields.status.filter({ id: 'unitStatus', label: 'Unit Status' }),
                    CK2Field.filter({
                        id: 'unitVmrsEquipmentCategory',
                        label: 'Equipment Category',
                    }),
                    ck34Fields.self.filter({
                        id: 'unitVmrsManufacturer',
                        label: 'Manufacturer/Make',
                    }),
                    unitFields.model.filter({ id: 'unitModel' }),
                    unitFields.modelYear.filter({ id: 'unitModelYear' }),
                    ck34Fields.self.filter({
                        id: 'unitEngineVmrsManufacturer',
                        label: 'Engine Make',
                    }),
                    unitFields.engineModel.filter({ id: 'unitEngineModel' }),
                    unitFields.engineHp.filter({ id: 'unitEngineHp' }),
                    ck34Fields.self.filter({
                        id: 'unitTransmissionVmrsManufacturer',
                        label: 'Transmission Make',
                    }),
                    unitFields.transmissionModel.filter({ id: 'unitTransmissionModel' }),
                    unitFields.transmissionGears.filter({ id: 'unitTransmissionGears' }),
                    unitFields.color.filter({ id: 'unitColor' }),
                    unitFields.tireSize.filter({ id: 'unitTireSize' }),
                    tagFields.self.filter({ id: 'unitTags' as keyof CK33ReportModel }),
                    reportCostCenterFilter(auth),
                    reportDivisionFilter(auth),
                ],
            }

            return (
                <ResourceContextProviderWithClearEffect value={ck33Resource}>
                    <ListBaseWithPeriod
                        sort={defaultSort}
                        filter={{ withAspects: aspects }}
                        preferencesResource={preferences}
                    >
                        <ReportsHeader<CK33ReportModel> renderTotal={renderTotal}>
                            {title}
                        </ReportsHeader>
                        <PageContent>
                            <ListWithPeriodSelector
                                preferencesResource={preferences}
                                exportFileName={exportFileName}
                                filtersCfg={filterConfig}
                                sortCfg={sortConfig}
                                columnsCfg={columnsConfig}
                                cardsCfg={cardsConfig}
                            />
                        </PageContent>
                    </ListBaseWithPeriod>
                </ResourceContextProviderWithClearEffect>
            )
        },
    ),
)

export default CostPerCK33
