import { type FC } from 'react'

import {
    EditButton,
    InfoCard,
    InfoCardHeader,
    InfoCardRowBase,
    InfoCardRows,
    InfoCardTitle,
    MoneyField,
} from 'components'
import { type InvoiceModel } from 'resources/invoices'
import { Divider } from 'ui'

import FeeRow from './FeeRow'
import ManageTotalDrawerToggler from './ManageTotalDrawerToggler'
import TaxRow from './TaxRow'

interface TotalCardProps extends Pick<InvoiceModel, 'fees' | 'taxes' | 'total' | 'amountDue'> {}
const TotalCard: FC<TotalCardProps> = ({ amountDue, fees, taxes, total }) => {
    return (
        <InfoCard>
            <InfoCardHeader
                title="Total"
                action={
                    <ManageTotalDrawerToggler>
                        {(open) => <EditButton onClick={open} />}
                    </ManageTotalDrawerToggler>
                }
            />
            <InfoCardRowBase label={<InfoCardTitle>Subtotal</InfoCardTitle>}>
                <MoneyField value={amountDue} />
            </InfoCardRowBase>
            <InfoCardRows>
                <FeeRow value={fees} />

                <TaxRow value={taxes} />
            </InfoCardRows>
            <Divider />
            <InfoCardRows offsetBottom="0">
                <InfoCardRowBase label={<InfoCardTitle>Grand Total</InfoCardTitle>}>
                    <InfoCardTitle>
                        <MoneyField value={total} />
                    </InfoCardTitle>
                </InfoCardRowBase>
            </InfoCardRows>
        </InfoCard>
    )
}

export default TotalCard
